export function galleryPopup($) {
  const $bigSlides = $('.toolbox-gallery .thumbnail-slider .slide')
  const $thumbSlides = $('.toolbox-gallery .slick-slider-thumbs .slide--popup')

  for (let i = 0; i < $thumbSlides.length; i++) {
    $($thumbSlides[i]).click(function () {
      console.log('click', $thumbSlides[i], $bigSlides[i])
      if ($bigSlides[i]) {
        const pictures = $($bigSlides[i]).children('picture')
        if (pictures.length > 0) {
          const picture = $(pictures[0]).clone()
          const popupContainer = $(
            '<div id="popup-gallery" class="popup-gallery fixed inset-0 bg-gray-900/80 grid justify-center items-center "></div>'
          )
          popupContainer.append(picture)
          const closeButton = $(
            '<div class="popup-close-btn absolute top-8 right-8 text-white text-3xl hover:opacity-60 hover:cursor-pointer">X</div>'
          )

          $(closeButton).click(function () {
            $('#popup-gallery').remove()
          })

          popupContainer.append(closeButton)

          $('#popup-gallery').remove()
          $('footer').after(popupContainer)
        }
      }
    })
  }
}
