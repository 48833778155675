// import {clearAllWpAbsoluteRef} from './html-tools'
import { galleryPopup } from './gallery'

// executed on loaded
if (typeof window !== 'undefined' && window?.jQuery) {
  ;(function ($) {
    // clearAllWpAbsoluteRef($)
    galleryPopup($)
  })(window.jQuery)
}
